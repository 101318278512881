interface P {
  data: any;
  column: string;
  className?: string;
  render?: (v: any) => any;
}

export default function Td(p: P) {
  const val = p.data[p.column];
  let renderStr = "";
  let attr = {};

  if (typeof val === "object") {
    renderStr = val.value;
    attr = { ...val, value: undefined };
  } else {
    renderStr = val;
  }

  if (!renderStr) {
    return null;
  }

  if (p.render) {
    return (
      <td className={p.className} {...attr}>
        {p.render(renderStr)}
      </td>
    );
  }

  if (typeof val !== "object") {
    return <td className={p.className}>{renderStr}</td>;
  }

  return (
    <td className={p.className} {...attr}>
      {renderStr}
    </td>
  );
}
