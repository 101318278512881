/**
 * 阳离子
 */

import { useMemo } from "react";
import { useLocalProviderContext } from "../../components/LocalProvider";
import type { ColumnsType } from "../../components/Table";
import Table from "../../components/Table";
import useProductsHelper from "../../hooks/products.helper";
import cationicZH from "./cationic-zh.json";
import cationicEN from "./cationic-en.json";

import styles from "./product.module.scss";

export default function Cationic() {
  const { lang } = useLocalProviderContext();
  const products = useProductsHelper(cationicZH, cationicEN);

  const columns: ColumnsType[] = useMemo(
    () => [
      { title: lang.id, dataIndex: "id" },
      { title: lang.inci, dataIndex: "inci" },
      { title: lang.appearance, dataIndex: "appearance" },
      { title: lang.speciality, dataIndex: "speciality" },
      { title: lang.application, dataIndex: "application", width: "120px" },
    ],
    [lang]
  );

  const columns1: ColumnsType[] = useMemo(
    () => [
      { title: lang.id, dataIndex: "id", width: "150px" },
      { title: lang.inci, dataIndex: "inci" },
      { title: lang.appearance, dataIndex: "appearance" },
      { title: lang.solidContent + " %", dataIndex: "percent" },
      { title: lang.speciality, dataIndex: "speciality" },
      { title: lang.application, dataIndex: "application", width: "120px" },
    ],
    [lang]
  );

  return (
    <div className={styles.emulsifier}>
      {products.map((product, index) => (
        <div className={styles["product-item"]} key={index}>
          <div className={styles["product-title"]}>{product.name}</div>
          <Table
            columns={index === 0 ? columns : columns1}
            data={product.products}
            dataKey="name"
          />
        </div>
      ))}
    </div>
  );
}
