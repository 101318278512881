import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "rc-dropdown";

import { useLocalProviderContext } from "../LocalProvider";

import styles from "./product-nav.module.scss";

interface P {
  className?: string;
}

export default function ProductNav(p: P) {
  const [show, setShow] = useState(false);
  const { lang } = useLocalProviderContext();

  const products = useMemo(
    () => [
      {
        name: lang.emulsifier,
        path: "emulsifier",
        subTitle: lang.emulsifierSubTitle,
        imgUrl: "/assets/image/emulsifier.jpeg",
      },
      {
        name: lang.aminoAcid,
        path: "amino-acid",
        imgUrl: "/assets/image/amino-acid.jpeg",
        subTitle: lang.aminoAcidSubTitle,
      },
      {
        name: lang.colloid,
        path: "colloid",
        imgUrl: "/assets/image/colloid.jpeg",
        subTitle: lang.colloidSubTitle,
      },
      {
        name: lang.cationic,
        path: "cationic",
        imgUrl: "/assets/image/cationic.jpeg",
        subTitle: lang.cationicSubTitle,
      },
    ],
    [lang]
  );

  const menu = (
    <div className={styles.portal}>
      <div className={styles.list}>
        {products.map((p) => (
          <Link to={`./product/${p.path}`} key={p.name}>
            <div className={styles["list-item"]}>
              <div className={styles.title}>{p.name}</div>
              <div className={styles["sub-title"]}>{p.subTitle}</div>
              <div className={styles.img}>
                <img src={p.imgUrl} width="100%" alt="" />
              </div>
            </div>
          </Link>
        ))}

        <Link to="/product/emulsifier">
          <div className={styles.more}>{lang.more} &gt;&gt;</div>
        </Link>
      </div>
    </div>
  );

  return (
    <Dropdown
      trigger={["click", "hover"]}
      onVisibleChange={(show) => setShow(show)}
      visible={show}
      overlay={menu}
      animation="slide-up"
      overlayClassName="full-width"
    >
      <Link to="/product/emulsifier" className={p.className}>
        {lang.product}
      </Link>
    </Dropdown>
  );
}
