import { Link } from "react-router-dom";

import ProductNav from "../ProductNav";
import AboutNav from "../AboutNav";
import LangSwitcher from "./LangSwitcher";
import { useLocalProviderContext } from "../LocalProvider";

import styles from "./nav.module.scss";

export default function Nav() {
  const { lang } = useLocalProviderContext();

  return (
    <div className={styles.nav}>
      <Link to="/" className={styles["nav-item"]}>
        {lang.home}
      </Link>
      <ProductNav className={styles["nav-item"]} />
      {/* <div className={styles["nav-item"]}>{lang.news}</div> */}
      <AboutNav className={styles["nav-item"]} />
      <LangSwitcher />
    </div>
  );
}
