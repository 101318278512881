import { useMemo } from "react";

import { useLocalProviderContext } from "../components/LocalProvider";

export default function useContractList() {
  const { lang } = useLocalProviderContext();

  const contractList = useMemo(
    () => [
      {
        name: `${lang.address}: ${lang.companyAddress}`,
        addr: "#",
        out: false,
      },
      { name: `${lang.tel}: +86 132 6549 6860`, addr: "#" },
      {
        name: `${lang.email}: aulee.chemical@gmail.com`,
        addr: "mailto:aulee.chemical@gmail.com",
      },
      // { name: lang.wechat, addr: "#" },
      {
        name: lang.facebook,
        addr: "https://www.facebook.com/AuLeeChemical",
        out: true,
      },
      {
        name: lang.twitter,
        addr: "https://twitter.com/AuLeeChemical",
        out: true,
      },
      {
        name: lang.instagram,
        addr: "https://instagram.com/auleechemical?igshid=ZDdkNTZiNTM=",
        out: true,
      },
      { name: `${lang.whatsapp}: +86 132 6549 6860`, addr: "#" },
      // {
      //   name: lang.tiktok,
      //   addr: "https://www.tiktok.com/@auleechemical",
      //   out: true,
      // },
    ],
    [lang]
  );

  return contractList;
}
