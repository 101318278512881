/**
 * 去屑杀菌
 */

import { useMemo } from "react";
import { useLocalProviderContext } from "../../components/LocalProvider";

import type { ColumnsType } from "../../components/Table";
import Table from "../../components/Table";
import antiDandruffZH from "./anti-dandruff-zh.json";
import antiDandruffEN from "./anti-dandruff-en.json";

import styles from "./product.module.scss";
import useProductsHelper from "../../hooks/products.helper";

export default function AntiDandruff() {
  const products = useProductsHelper(antiDandruffZH, antiDandruffEN);
  const { lang } = useLocalProviderContext();

  const columns: ColumnsType[] = useMemo(
    () => [
      { title: lang.id, dataIndex: "id" },
      { title: lang.inci, dataIndex: "inci" },
      { title: lang.appearance, dataIndex: "appearance" },
      { title: lang.speciality, dataIndex: "speciality" },
      { title: lang.application, dataIndex: "application", width: "120px" },
    ],
    [lang]
  );

  return (
    <div className={styles.emulsifier}>
      <div className={styles["product-item"]}>
        <Table columns={columns} data={products} dataKey="name" />
      </div>
    </div>
  );
}
