import { Link } from "react-router-dom";

import useProductsHelper from "../../../../hooks/products.helper";
import { useLocalProviderContext } from "../../../../components/LocalProvider";
import productListEn from "./product-en.json";
import productListZh from "./product-zh.json";

import styles from "./product-list.module.scss";

export default function ProductList() {
  const { lang } = useLocalProviderContext();
  const products = useProductsHelper(productListZh, productListEn);

  return (
    <>
      <h2 className={styles["product-title"]}>
        PRODUCTS<span className={styles.slash}>/</span>
        <span className={styles["sub-title"]}>我们的产品</span>
      </h2>
      {products.map((item, index) => (
        <div key={item.name} className={styles["pl-item"]}>
          {index % 2 === 0 ? (
            <>
              <div className={styles.desc}>
                <div className={styles.title}>{item.name}</div>
                <div>{item.description}</div>
                <div>
                  <Link to={item.more} className={styles.more}>
                    {lang.more}
                    {" >>"}
                  </Link>
                </div>
              </div>
              <div className={styles.img}>
                <img src={item.img} alt="" width="300" />
              </div>
            </>
          ) : (
            <>
              <div className={styles.img}>
                <img src={item.img} alt="" width="300" />
              </div>
              <div className={styles.desc}>
                <div className={styles.title}>{item.name}</div>
                <div>{item.description}</div>
                <div>
                  <Link to={item.more} className={styles.more}>
                    {lang.more}
                    {" >>"}
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </>
  );
}
