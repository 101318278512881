/**
 * 胶体
 */

import { useMemo } from "react";

import { useLocalProviderContext } from "../../components/LocalProvider";
import type { ColumnsType } from "../../components/Table";
import Table from "../../components/Table";
import useProductsHelper from "../../hooks/products.helper";
import colloidZH from "./colloid-zh.json";
import colloidEN from "./colloid-en.json";

import styles from "./product.module.scss";

export default function Colloid() {
  const { lang } = useLocalProviderContext();
  const products = useProductsHelper(colloidZH, colloidEN);

  const columns: ColumnsType[] = useMemo(
    () => [
      { title: lang.id, dataIndex: "id" },
      { title: lang.productName, dataIndex: "name", width: "170px" },
      { title: lang.appearance, dataIndex: "appearance" },
      { title: lang.specification, dataIndex: "specification" },
      { title: lang.productDescription, dataIndex: "description" },
    ],
    [lang]
  );

  return (
    <div className={styles.emulsifier}>
      <div className={styles["product-item"]}>
        <Table columns={columns} data={products} dataKey="name" />
      </div>
    </div>
  );
}
