/**
 * 表活乳化剂
 */

import { useMemo } from "react";

import Table from "../../components/Table";
import type { ColumnsType } from "../../components/Table";
import { useLocalProviderContext } from "../../components/LocalProvider";
import useProductsHelper from "../../hooks/products.helper";
import emulsifierZH from "./emulsifier-zh.json";
import emulsifierEN from "./emulsifier-en.json";

import styles from "./product.module.scss";

export default function Emulsifier() {
  const { lang } = useLocalProviderContext();
  const products = useProductsHelper(emulsifierZH, emulsifierEN);

  const columns: ColumnsType[] = useMemo(
    () => [
      { title: lang.id, dataIndex: "id", width: "110px" },
      // { title: "对应型号", dataIndex: "type", width: "100px" },
      { title: lang.inci, dataIndex: "inci", width: "200px" },
      { title: lang.appearance, dataIndex: "appearance", width: "150px" },
      { title: lang.specification, dataIndex: "specification", width: "150px" },
      { title: lang.performance, dataIndex: "usage" },
    ],
    [lang]
  );

  return (
    <div className={styles.emulsifier}>
      {products.map((product, index) => (
        <div className={styles["product-item"]} key={index}>
          <div className={styles["product-title"]}>{product.name}</div>
          <Table columns={columns} data={product.products} dataKey="name" />
        </div>
      ))}
    </div>
  );
}
