import Carousel from "./components/Carousel";
import ProductList from "./components/ProductList";

export default function Home() {
  return (
    <>
      <Carousel />
      <ProductList />
    </>
  );
}
