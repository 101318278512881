import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "rc-dropdown";

import { useLocalProviderContext } from "../LocalProvider";

import styles from "./about-nav.module.scss";

interface P {
  className?: string;
}

export default function AboutNav(p: P) {
  const [show, setShow] = useState(false);
  const { lang } = useLocalProviderContext();

  const menus = useMemo(
    () => (
      <div className={styles.menu}>
        {[
          { name: lang.aboutUs, path: "./about-us" },
          { name: lang.contractUs, path: "./contract-us" },
          // { name: lang.partners, path: "./partners" },
        ].map((v, idx) => (
          <Link className={styles["menu-item"]} key={idx} to={v.path}>
            {v.name}
          </Link>
        ))}
      </div>
    ),
    [lang]
  );

  return (
    <Dropdown
      trigger={["click", "hover"]}
      onVisibleChange={(show) => setShow(show)}
      visible={show}
      overlay={menus}
      animation="slide-up"
    >
      <div className={p.className}>{lang.about}</div>
    </Dropdown>
  );
}
