import Td from "./Td";

import styles from "./table.module.scss";

export interface ColumnsType {
  title: string;
  dataIndex: string;
  width?: string;
}

interface P {
  columns: ColumnsType[];
  data: any[];
  dataKey?: string;
}

export default function Table(p: P) {
  const { columns, data, dataKey: key = "id" } = p;
  return (
    <table className={styles.table}>
      <colgroup>
        {columns.map((v) => (
          <col key={v.dataIndex} width={v.width || undefined} />
        ))}
      </colgroup>
      <thead>
        <tr>
          {columns.map((v) => (
            <th key={v.dataIndex}>{v.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((v) => (
          <tr key={v[key]}>
            {columns.map((c) => (
              <Td key={c.dataIndex} column={c.dataIndex} data={v} />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
