import { useEffect, useState } from "react";
import { useLocalProviderContext } from "../components/LocalProvider";

export default function useProductsHelper<T>(zh: T, en: T) {
  const [products, setProducts] = useState(zh);
  const { locale } = useLocalProviderContext();

  useEffect(() => {
    setProducts(locale === "zh" ? zh : en);
  }, [locale, zh, en]);

  return products;
}
