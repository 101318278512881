/**
 * 色粉
 */

import { lazy, Suspense, useMemo, useState } from "react";
import cx from "classnames";
import { useLocalProviderContext } from "../../../components/LocalProvider";

import styles from "./color-powder.module.scss";

const MedicineGrade = lazy(() => import("./MedicineGrade"));
const CosmeticGrade = lazy(() => import("./CosmeticGrade"));
const FoodGrade = lazy(() => import("./FoodGrade"));

export default function ColorPowder() {
  const { lang } = useLocalProviderContext();
  const categories = [
    lang.medicineGradeColorPower,
    lang.cosmeticGradeColorPower,
    lang.foodGradeColorPower,
  ];
  const [active, setActive] = useState(0);
  const Display = useMemo(() => {
    switch (active) {
      case 0:
        return MedicineGrade;
      case 1:
        return CosmeticGrade;
      case 2:
        return FoodGrade;
      default:
        return MedicineGrade;
    }
  }, [active]);

  return (
    <div className={styles.tabs}>
      <div className={styles["tabs-nav"]}>
        {categories.map((v, idx) => (
          <div
            key={v}
            className={cx(styles["tabs-tab"], active === idx && styles.active)}
            onClick={() => setActive(idx)}
          >
            {v}
          </div>
        ))}
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Display />
      </Suspense>
    </div>
  );
}
