import { RouterProvider } from "react-router-dom";

import router from "./router";

import "./App.css";
import "rc-dropdown/assets/index.css";

function App() {
  return <RouterProvider router={router} />;
}

export default App;
