import { useLocalProviderContext } from "../../components/LocalProvider";
import useProductsHelper from "../../hooks/products.helper";

import aboutUsZH from "./about-us-zh.json";
import aboutUsEN from "./about-us-en.json";

import styles from "./about.module.scss";

export default function AboutUsPage() {
  const { lang } = useLocalProviderContext();
  const context = useProductsHelper(aboutUsZH, aboutUsEN);

  return (
    <>
      <img src="/assets/image/about-header.jpeg" width="100%" alt="" />
      <div className={styles.container}>
        <h2 className="upper-case">{lang.aboutUs}</h2>
        <div className={styles.context}>
          <div className={styles["context-left"]}>
            {context.map((v, index) => (
              <p className={styles.ph} key={index}>
                {v}
              </p>
            ))}
          </div>
          <div>
            <img src="/assets/image/aoli-lab-1.webp" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
