import { useLocalProviderContext } from "../components/LocalProvider";
import Nav from "../components/Nav";

import styles from "./header.module.scss";

export default function Header() {
  const { lang } = useLocalProviderContext();

  return (
    <header>
      <div className={styles.header}>
        <div className={styles["header-container"]}>
          <div className={styles.logo}>
            <img src="/assets/image/logo.png" height="100%" alt="" />
          </div>
          <div className={styles["logo-name"]}>{lang.name}</div>
          <Nav />
        </div>
      </div>
    </header>
  );
}
