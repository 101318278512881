import { Link, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import cx from "classnames";

import productList from "./products.json";

import styles from "./product.module.scss";
import { useLocalProviderContext } from "../../components/LocalProvider";

export default function Product() {
  const [active, setActive] = useState("");
  const location = useLocation();
  const { lang } = useLocalProviderContext();

  useEffect(() => {
    const [, , path] = location.pathname.split("/");
    setActive(path || productList[0].path);
  }, [location.pathname]);

  return (
    <div className={styles.product}>
      <div className={styles["menu-list"]}>
        <div className={styles["fixed-menu"]}>
          {productList.map((v: any) => (
            <div
              className={cx(
                styles["menu-item"],
                active === v.path && styles.active
              )}
              key={v.name}
            >
              <Link to={`./${v.path}`}>
                {lang[v.name]}
                <div className={styles["sub-title"]}>{lang[v.subTitle]}</div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
}
