import cx from "classnames";

import useContractList from "../../hooks/contractList.hook";
import { useLocalProviderContext } from "../../components/LocalProvider";

import styles from "./about.module.scss";
import IconOut from "../../components/Icons/IconOut";

export default function ContractUsPage() {
  const { lang } = useLocalProviderContext();
  const contractList = useContractList();

  return (
    <>
      <img src="/assets/image/contract-us.jpeg" width="100%" alt="" />
      <div className={cx(styles.container, styles["contract-us"])}>
        <h2 className="upper-case">{lang.contractUs}</h2>
        <div>{lang.keepTouch}</div>
        <ul className={styles["contract-list"]}>
          {contractList.map((v, index) => (
            <li key={index} className={styles.item}>
              <a href={v.addr}>
                {v.name}
                {v.out ? <IconOut className={styles["icon-out"]} /> : null}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
