import { Outlet } from "react-router-dom";
import LocalProvider from "../components/LocalProvider";
import Footer from "./Footer";
import Header from "./Header";

export default function Layout() {
  return (
    <LocalProvider>
      <Header />
      <div className="content">
        <Outlet />
      </div>
      <Footer />
    </LocalProvider>
  );
}
