import { Link } from "react-router-dom";
import useContractList from "../hooks/contractList.hook";
import { useLocalProviderContext } from "../components/LocalProvider";

import products from "../pages/product/products.json";

import styles from "./footer.module.scss";
import IconOut from "../components/Icons/IconOut";

export default function Footer() {
  const { lang } = useLocalProviderContext();
  const contractList = useContractList();

  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <section className={styles.section}>
          Copyright &copy; {lang.name}
        </section>
        <div className={styles.section}>
          <div className={styles.title}>{lang.product}</div>
          {products.map((v, index) => (
            <Link to={`/product/${v.path}`} className={styles.item} key={index}>
              {lang[v.name]}
            </Link>
          ))}
          <Link to="/product/emulsifier" className={styles.more}>
            {lang.more} &gt;&gt;
          </Link>
        </div>
        <div className={styles.section}>
          <div className={styles.title}>{lang.contractUs}</div>
          {contractList.map((v, index) => (
            <a
              href={v.addr}
              className={styles.item}
              key={index}
              target={v.out ? "_blank" : undefined}
              rel="noreferrer"
            >
              {v.name}
              {v.out ? <IconOut className={styles["icon-out"]} /> : null}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
