import {
  createContext,
  memo,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";

import zh from "./zh.json";
import en from "./en.json";

const SET_LANG = "SET_LANG";

function localReducer(
  initState = { lang: zh, locale: "en" },
  action: { type: string; payload: "zh" | "en" }
): any {
  switch (action.type) {
    case SET_LANG:
      return {
        lang: action.payload === "en" ? en : zh,
        locale: action.payload,
      };
  }

  return initState;
}

export function setLang(payload: "zh" | "en") {
  return {
    type: SET_LANG,
    payload,
  };
}

const LocalProviderContext = createContext<{
  lang: any;
  locale: string;
  dispatch: any;
}>({
  lang: en,
  locale: "en",
  dispatch: null,
});

export function useDispatch() {
  const { dispatch } = useContext(LocalProviderContext);
  return dispatch;
}

export function useLocalProviderContext() {
  return useContext(LocalProviderContext);
}

function LocalProvider({ children }: PropsWithChildren) {
  const [store, dispatch] = useReducer(localReducer, {
    lang: en,
    locale: "en",
  });

  return (
    <LocalProviderContext.Provider value={{ ...store, dispatch }}>
      {children}
    </LocalProviderContext.Provider>
  );
}

export default memo(LocalProvider);
