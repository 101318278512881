/**
 * 氨基酸
 */

import { useMemo } from "react";

import type { ColumnsType } from "../../components/Table";
import Table from "../../components/Table";
import { useLocalProviderContext } from "../../components/LocalProvider";
import aminoAcidZH from "./amino-acid-zh.json";
import aminoAcidEN from "./amino-acid-en.json";

import styles from "./product.module.scss";
import useProductsHelper from "../../hooks/products.helper";

export default function AminoAcid() {
  const { lang } = useLocalProviderContext();
  const products = useProductsHelper(aminoAcidZH, aminoAcidEN);

  const columns: ColumnsType[] = useMemo(
    () => [
      { title: lang.id, dataIndex: "id", width: "130px" },
      { title: lang.productName, dataIndex: "name", width: "130px" },
      { title: lang.appearance, dataIndex: "appearance" },
      { title: lang.specification, dataIndex: "specification" },
      { title: lang.productDescription, dataIndex: "description" },
    ],
    [lang]
  );

  return (
    <div className={styles.emulsifier}>
      <div className={styles["product-item"]}>
        <Table columns={columns} data={products} dataKey="name" />
      </div>
    </div>
  );
}
