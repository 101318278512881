import { useEffect, useRef } from "react";
import cx from "classnames";

import { Carousel as BSCarousel } from "./carousel";

import "./carousel.scss";
import styles from "./carousel.module.scss";

export default function Carousel() {
  const ref = useRef(null);
  const banner = [
    "/assets/image/banner2.jpg",
    "/assets/image/banner3.jpg",
    "/assets/image/banner4.jpeg",
  ];

  useEffect(() => {
    BSCarousel.getOrCreateInstance(ref.current);
  }, []);

  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-bs-ride="true"
      ref={ref}
    >
      <div className="carousel-indicators">
        {banner.map((v, idx) => (
          <button
            key={idx}
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={idx}
            className={idx === 0 ? "active" : ""}
            aria-current="true"
            aria-label={`Banner ${idx + 1}`}
          />
        ))}
      </div>
      <div className="carousel-inner">
        {banner.map((v, idx) => (
          <div className={cx("carousel-item", idx === 0 && "active")} key={idx}>
            <div className={styles.img}>
              <img src={v} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
