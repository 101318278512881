import { useCallback, useMemo, useState } from "react";
import Dropdown from "rc-dropdown";

import { setLang, useDispatch } from "../LocalProvider";
import IconI18n from "../Icons/IconI18n";

import styles from "./nav.module.scss";

export default function LangSwitcher() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const change = useCallback(
    (lang: "zh" | "en") => {
      dispatch(setLang(lang));
    },
    [dispatch]
  );

  const menus = useMemo(
    () => (
      <div className={styles.lang}>
        <div className={styles["lang-item"]} onClick={() => change("zh")}>
          中文
        </div>
        <div className={styles["lang-item"]} onClick={() => change("en")}>
          English
        </div>
      </div>
    ),
    [change]
  );

  return (
    <Dropdown
      trigger={["click"]}
      onVisibleChange={(show) => setShow(show)}
      visible={show}
      overlay={menus}
      animation="slide-up"
    >
      <div className={styles["nav-i18n"]}>
        <IconI18n onClick={() => setShow(true)} />
      </div>
    </Dropdown>
  );
}
