import { createBrowserRouter } from "react-router-dom";
import Layout from "../layout";
import Home from "../pages/home";
import Product from "../pages/product";
import Emulsifier from "../pages/product/Emulsifier";
import AminoAcid from "../pages/product/AminoAcid";
import Colloid from "../pages/product/Colloid";
import Cationic from "../pages/product/Cationic";
import ColorPowder from "../pages/product/ColorPowder";
import AntiDandruff from "../pages/product/AntiDandruff";
import ContractUsPage from "../pages/about/ContractUs";
import AboutUsPage from "../pages/about/AboutUs";
// import PartnersPage from "../pages/about/Partners";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "/product",
        element: <Product />,
        children: [
          { index: true, path: "emulsifier", element: <Emulsifier /> },
          { path: "amino-acid", element: <AminoAcid /> },
          { path: "colloid", element: <Colloid /> },
          { path: "cationic", element: <Cationic /> },
          { path: "color-powder", element: <ColorPowder /> },
          { path: "anti-dandruff", element: <AntiDandruff /> },
        ],
      },
      {
        path: "/about-us",
        element: <AboutUsPage />,
      },
      {
        path: "/contract-us",
        element: <ContractUsPage />,
      },
      // {
      //   path: "/partners",
      //   element: <PartnersPage />,
      // },
    ],
  },
]);

export default router;
